import React, { useRef, useState } from 'react';
import axios from 'axios';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { v4 as uuidv4 } from 'uuid';
import '../../App.css';
import "./CreatePage.css";
import ThemeToggle from '../../components/ToggleComponent';

interface FormValues {
  sortDate: Date | null;
  advancedMode: boolean;
  budget: number;
}

const CreatePage: React.FC = () => {
  const toastBC = useRef<Toast>(null);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [gameLink, setGameLink] = useState<string>('');
  const currentYear = new Date().getFullYear();
  const minDate = new Date(new Date().setDate(new Date().getDate() + 1));
  const maxDate = new Date(currentYear, 11, 23);
  const initialValues: FormValues = { sortDate: minDate, advancedMode: false, budget: 10 };

  const validationSchemaStep1 = Yup.object({
    budget: Yup.number().min(1).required('Il budget è obbligatorio'),
  });
  const validationSchemaStep2 = Yup.object({
    sortDate: Yup.date().min(minDate, `La data deve essere successiva a ${minDate.toLocaleDateString()}`).max(maxDate, `La data deve essere precedente a ${maxDate.toLocaleDateString()}`).required('La data è obbligatoria'),
  });

  const getDeviceId = (): string => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
  };

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setStep((prev) => prev - 1);
  };

  const handleSubmit = async (values: FormValues) => {
    setShowForm(false);
    setIsLoading(true);

    try {
      const normalizedSortDate = values.sortDate
        ? new Date(values.sortDate.getTime() - values.sortDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]
        : null;

      const dataToSend = {
        ...values,
        sortDate: normalizedSortDate, // Normalizza sortDate
        uuid: getDeviceId(),
      };

      const response = await axios.post('/create', dataToSend);
      if (response.data.success) {
        setGameLink(response.data.data.link);
        setShowForm(false);
      }
    } catch (error) {
      setShowForm(true);
      toastBC.current?.show({
        severity: 'error',
        summary: (error as any).response?.data?.message || 'Errore durante la creazione del gioco',
        sticky: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toastBC} position="top-center" />
      <div className="container">
        <div className="avatar">
          <div className="avatar-image"></div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={step === 1 ? validationSchemaStep1 : validationSchemaStep2}
          onSubmit={handleSubmit}
          className={showForm ? '' : 'hide'}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              <Form className={showForm ? 'form' : 'form hide'}>
                {step === 1 && (
                  <>
                    <div className="form-field">
                      <label htmlFor="budget"><h2>Budget</h2></label>
                      <InputNumber
                        id="budget"
                        name="budget"
                        value={values.budget}
                        onValueChange={(e) => setFieldValue('budget', e.value)}
                        mode="currency"
                        currency="EUR"
                        locale="it-IT" />
                      <ErrorMessage name="budget" component="div" className="error" />
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className="form-field">
                      <label htmlFor="sortDate"><h2>Data di estrazione</h2></label>
                      <p>inserisci la data in cui vuoi che vengano estratte le combinazioni e comunicate via email</p>
                      <Calendar
                        id="sortDate"
                        name="sortDate"
                        minDate={minDate}
                        maxDate={maxDate}
                        value={values.sortDate}
                        onChange={(e) => setFieldValue('sortDate', e.value || null)}
                        dateFormat="dd/mm/yy"
                        showIcon
                        touchUI={true} // Per migliorare l'uso su dispositivi mobili
                        timeOnly={false} // Garantisce che l'orario non influenzi la selezione
                      />
                      <ErrorMessage name="sortDate" component="div" className="error" />
                    </div>
                    <div className="form-field">
                      <label htmlFor="advancedMode"><h2>Versione avanzata</h2></label>
                      <Field name="advancedMode">
                        {() => (
                          <ThemeToggle
                            value={values.advancedMode}
                            onChange={(advancedValue) => setFieldValue('advancedMode', advancedValue)}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="advancedMode" component="div" className="error" />
                      <p>Nella versione avanzata del gioco, alcuni partecipanti potrebbero ricevere indicazioni sul regalo, comprese limitazioni su quest ultimo o variazioni del bidget minimo.</p>
                    </div>
                  </>
                )}
              </Form>
              <div className={showForm ? 'cta-box' : 'cta-box hide'}>
                {step === 1 && (
                  <button type="button" onClick={handleNextStep} className="cta w-90 next-button">Avanti</button>
                )}
                {step === 2 && (
                  <>
                    <button type="button" onClick={handlePreviousStep} className="cta w-90 secondary previous-button">Indietro</button>
                    <button type="button" onClick={submitForm} className="cta w-90 primary">Dai inizio al gioco!</button>
                  </>
                )}
              </div>
            </>
          )}
        </Formik>

        <div className={isLoading ? '' : 'hide'}>
          <div className="loader"></div>
        </div>
        <div className={isLoading || showForm ? 'hide' : ''}>
          <h2>Codice creato con successo</h2>
          <p>Controlla la tua casella di posta<br />
            Puoi condividere questo link con i tuoi amici per procedere alla registrazione: <br />
            {gameLink ? (
              <a href={gameLink} target="_blank" rel="noopener noreferrer">
                Vai al gioco
              </a>
            ) : (
              <span>Caricamento link del gioco...</span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default CreatePage;