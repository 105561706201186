import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import SubscriptionPage from './pages/SubscriptionPage/SubscriptionPage';
import SuccessPage from './pages/SuccessPage/SuccessPage';
import CreatePage from './pages/CreatePage/CreatePage';
import FormPage from './pages/FormPage/FormPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Rotte definite per ogni pagina */}
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/create" element={<CreatePage />} />

        {/* Redirect alla pagina di benvenuto come default */}
        <Route path="*" element={<Navigate to="/welcome" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
