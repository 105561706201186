import React from 'react';
import '../../App.css';
import "./SubscriptionPage.css";
import { useNavigate } from 'react-router-dom';

const SubscriptionPage: React.FC = () => {

  const navigate = useNavigate();

  const handleCreate = () => {
    navigate('/create');
  };

  return (
    <div className="container">
      <div className="avatar">
        <div className="avatar-image"></div>
      </div>
      <h2>Sembra tu non abbia un codice di partecipazione.</h2>
      <div className='cta-box'>
        <button type="button" onClick={handleCreate} className="w-90 secondary">Creane uno</button>
      </div>
    </div>
  );
};

export default SubscriptionPage;
