import React from 'react';
import '../../App.css';
import "./WelcomePage.css";
import { useNavigate, useSearchParams } from 'react-router-dom';

const WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Recupera i query params
  const code = searchParams.get('code'); // Recupera il parametro "code"

  const handleForm = () => {
    if (!code) {
      navigate('/subscription');
    } else {
      navigate('/form?code=' + code);
    }
  };

  return (
    <div className="container">
      <div className="avatar">
        <div className="avatar-image"></div>
      </div>

      <h2>Benvenuto nel gioco del Babbo Natale Segreto</h2>
      <div className="cta-box">
        <button onClick={handleForm} className="start-button w-90">Inizia</button>
      </div>
    </div>
  );
};

export default WelcomePage;
