import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import axios from 'axios';
import '../../App.css';
import "./FormPage.css";

// Definiamo il tipo per i dati della form
interface FormValues {
  name: string;
  email: string;
  note?: string;
}

const validationSchemaStep1 = Yup.object({
  name: Yup.string().required('Il nome è obbligatorio'),
  email: Yup.string().email('Inserisci un’email valida').required('L’email è obbligatoria'),
});

const validationSchemaStep2 = Yup.object({
  note: Yup.string(),
});

function FormPage() {
  const navigate = useNavigate();
  const toastBC = useRef<Toast>(null);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const initialValues: FormValues = { name: '', email: '', note: '' };
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!code) {
      navigate('/subscription');
    }
  }, [code, navigate]);

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setStep((prev) => prev - 1);
  };

  const handleSubmit = async (values: FormValues) => {
    // Send data to the server with axios
    try {
      const dataToSend = { ...values, roomCode: code };
      const response = await axios.post('/subscribe', dataToSend);

      if (response.status >= 200 && response.status < 300)
        navigate('/success');

    } catch (error) {
      toastBC.current?.show({
        severity: 'error',
        summary: (error as any).response?.data?.message || 'Errore durante la creazione del gioco',
        sticky: true,
      });
    }

  };

  return (
    <>
      <Toast ref={toastBC} position="top-center" />
      <div className="container">
        <div className="avatar">
          <div className="avatar-image"></div>
        </div>
        <h2>Compila il form</h2>

        <Formik
          initialValues={initialValues}
          validationSchema={step === 1 ? validationSchemaStep1 : validationSchemaStep2}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              <Form className="form">
                {step === 1 && (
                  <>
                    <div className="form-field">
                      <Field
                        type="text"
                        name="name"
                        className="input"
                        placeholder=" "
                      />
                      <label className="floating-label">Nome</label>
                      <ErrorMessage name="name" component="div" className="error" />
                    </div>
                    <div className="form-field">
                      <Field
                        type="email"
                        name="email"
                        className="input"
                        placeholder=" "
                      />
                      <label className="floating-label">Email</label>
                      <ErrorMessage name="email" component="div" className="error" />
                    </div>
                  </>
                )}

                {step === 2 && (
                  <>
                    <div className="form-field">
                      <Field
                        as="textarea"
                        name="note"
                        className="input"
                        placeholder=" "
                      />
                      <label className="floating-label">Note</label>
                      <ErrorMessage name="note" component="div" className="error" />
                    </div>
                  </>
                )}
              </Form>
              <div className='cta-box'>
                {step === 1 && (
                  <button type="button" onClick={handleNextStep} className="cta w-90 next-button">Avanti</button>
                )}
                {step === 2 && (
                  <>
                    <button type="button" onClick={handlePreviousStep} className="cta w-90 secondary previous-button">Indietro</button>
                    <button type="submit" onClick={submitForm} className="cta w-90 submit-button">Invia</button>
                  </>
                )}
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}

export default FormPage;
