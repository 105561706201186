import React from 'react';
import '../../App.css';
import "./SuccessPage.css";

const SuccessPage: React.FC = () => {
  return (
    <div className="container">
      <div className="avatar">
        <div className="avatar-image"></div>
      </div>
      <h2>Tutto Fatto!</h2>
      <p>Grazie per esserti registrato.</p>
      <p>Presto ti contatteremo via email per farti conoscere maggiori dettagli e darti istruzioni circa il regalo del tuo Secret Santa</p>
    </div>
  );
};

export default SuccessPage;
