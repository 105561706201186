import React from 'react';
import './ToggleComponent.css';
import easyIcon from '../assets/goodsanta.svg';
import devilsantaIcon from '../assets/devilsanta.svg';

interface ThemeToggleProps {
    value: boolean; // 'light' o true
    onChange: (value: boolean) => void;
}

const ToggleComponent: React.FC<ThemeToggleProps> = ({ value, onChange }) => {
    const isLight = value === false;

    const handleToggle = () => {
        onChange(isLight ? true : false);
    };

    return (
        <div className="theme-toggle" onClick={handleToggle}>
            <img
                src={easyIcon}
                alt="Light Mode"
                className={`toggle-icon easy ${isLight ? 'active' : ''}`}
            />
            <img
                src={devilsantaIcon}
                alt="advanced Mode"
                className={`toggle-icon advanced ${!isLight ? 'active' : ''}`}
            />
            <div className={`toggle-thumb ${isLight ? 'easy' : 'advanced'}`}></div>
        </div>
    );
};

export default ToggleComponent;
